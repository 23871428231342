import { previousOrdersReducer } from './PreviousOrders';
import { combineReducers } from 'redux';
import { authReducer, loginReducer } from 'src/redux/reducers/auth';
import { homeReducer } from 'src/redux/reducers/home';
import { queryMaterialDataReducer, customerDetailsReducer } from './customerDetails';
import { orderDetailsReducer } from './orderDetails';
import { viewQuotesReducer } from './viewQuotes';
import { quoteDetailsReducer } from './quoteDetails';
import { intransitOrderReducer } from './intransitOrder';
import { orderTnaReducer } from './orderTna';
import { productionOrdersReducer } from './productionOrders';
import { productionOrderDetailsReducer } from './productionDetails';
import { quoteAdditionalDetailsReducer } from './quoteAdditionalDetails';
import { createRequestReducer } from './createRequest';
import { customerFeedbackReducer } from './customerFeedback';
import { createCustomerFeedbackReducer } from './customerFeedbackPost';
import { getLocationDetailsReducer } from './locationDetails';
import { getAllAddressReducer } from './getAllAddress';
import { updateCustomerAddressReducer } from './updateCustomerAddress';
import { newAddressAddedReducer } from './addCustomerNewAddress';
import { autoLogisticCalculationReducer } from './autoLogisticCostReducer';
import { autoLogisticTopSellingReducer } from './autoLogisticReducerTopSelling';
import { provisionalOrderCreationReducer } from './provisionalOrder';
import { provisionalOrderTopSellingReducer } from './provisionalOrderTopSelling';
import { updateReferenceNumberReducer } from './referenceNumber';
import { orderConfigReducer, orderValidateReducer } from './orderConfig';
import { razorPayOrderCreationReducer } from './razorpayOrderReducer';
import { razorPayPaymentReducer } from './razorPayPaymentVerify';
import { cancelOrderReducer } from './cancelOrder';
import { getPaymentDetailsReducer } from './getPaymentDetails';
import { customerNotifyReducer } from './customerNotify';
import { customerOnboardViaGstReducer } from './customerOnboardViaGst';
import { gstNumberVerificationReducer } from './gstNumberVerification';
import storage from 'redux-persist/es/storage';
import { persistReducer } from 'redux-persist';
import { customerVPAReducer } from './customerVPA';
import { searchOrderReducer } from './searchOrder';
import { addGstSReducer } from './addGst';
import { fetchRaiseIssueDetailReducer } from './raiseIssueDetails';
import { raiseFabricIssueReducer } from './raiseIssue';
import { topQualityDetailsReducer } from './topSelling';
import { liveRatesDetailsReducer } from './liveRates';
import { filteredLiveRatesDetailsReducer } from './filteredLiveRates';
import { updateCreditPeriodReducer } from './updateCreditPeriod';
import { closeQueryReducer } from './closeQuery';
import { referenceCodeReducer } from './referenceCode';
import { verifyReferenceCodeReducer } from './verifyApprovalCode';
import { getReturnAndRefundPolicyReducer } from './getReturnAndRefundPolicy';
import { setTabReducer, setTabOffsetReducer } from './setTab';
import {currencyConversionReducer} from './currencyConversion';
import {addSampleApprovalReducer} from './addApprovalSample';
import { getCitiesReducer } from './getCities';
import { getbanksReducer } from './getBanks';
import { msmeDetailsReducer } from './msmeDetails'

const loginPersistConfig = {
  key: 'auth',
  storage: storage,
};

const authPersistConfig = {
  key: 'authNum',
  storage: storage,
};

export const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  login: persistReducer(loginPersistConfig, loginReducer),
  home: homeReducer,
  previousOrders: previousOrdersReducer,
  viewQuotes: viewQuotesReducer,
  quoteDetails: quoteDetailsReducer,
  order: orderDetailsReducer,
  customer: customerDetailsReducer,
  queryMaterial: queryMaterialDataReducer,
  intransitOrder: intransitOrderReducer,
  orderTna: orderTnaReducer,
  createRequest: createRequestReducer,
  quoteAdditionalDetails: quoteAdditionalDetailsReducer,
  topQualityDetails: topQualityDetailsReducer,
  liveRates: liveRatesDetailsReducer,
  filteredLiveRates: filteredLiveRatesDetailsReducer,
  productionOrders: productionOrdersReducer,
  productionOrderDetails: productionOrderDetailsReducer,
  getLocationDetails: getLocationDetailsReducer,
  getCustomerAllAddress: getAllAddressReducer,
  customerDetaislsViaGst: customerOnboardViaGstReducer,
  updateCustomerAddress: updateCustomerAddressReducer,
  newAddressAddedData: newAddressAddedReducer,
  autoLogisticValue: autoLogisticCalculationReducer,
  autoLogisticTopSellingValue: autoLogisticTopSellingReducer,
  provisionalOrder: provisionalOrderCreationReducer,
  referenceNumber: updateReferenceNumberReducer,
  orderConfig: orderConfigReducer,
  razorPayOrder: razorPayOrderCreationReducer,
  gstNumberVerify: gstNumberVerificationReducer,
  razorPayPaymentStatus: razorPayPaymentReducer,
  raiseFabricIssue: raiseFabricIssueReducer,
  cancleOrder: cancelOrderReducer,
  paymentDetails: getPaymentDetailsReducer,
  customerNotify: customerNotifyReducer,
  customerVPA: customerVPAReducer,
  searchOrder: searchOrderReducer,
  addGst: addGstSReducer,
  fetchRaiseIssueDetail: fetchRaiseIssueDetailReducer,
  getReturnAndRefundPolicy: getReturnAndRefundPolicyReducer,
  customerFeedbackDetails: customerFeedbackReducer,
  customerFeedbackDataPost: createCustomerFeedbackReducer,
  provisionalOrderTopSelling: provisionalOrderTopSellingReducer,
  updateCreditPeriod: updateCreditPeriodReducer,
  closeQuery: closeQueryReducer,
  madeToOrderAndBundledApprovalCode: referenceCodeReducer,
  verifyReferenceCode: verifyReferenceCodeReducer,
  orderValidate: orderValidateReducer,
  setTab: setTabReducer,
  setTabOffset: setTabOffsetReducer,
  getCurrencyRate: currencyConversionReducer,
  addSampleApproval: addSampleApprovalReducer,
  // get request cities 
  getCities:getCitiesReducer,
  getBanks:getbanksReducer,
  msmeDetails: msmeDetailsReducer
});

export type RootState = ReturnType<typeof rootReducer>;
